.header{
    height: 180px;
    background-image: url('./payment-banner.jpg');
}
.main{
    min-height: calc(100vh - 240px);
}

.maxContainer{
    max-width: 30rem;
}

.footer{
    height: 60px;
}